<template>
  <div class="form-group">

    <div class="input-group">
      <div class="label">{{label}}</div>
      <label>
        <select @change="changeHandler" class="form-control hour" v-model="hour" :disabled="disabled">
          <option v-for="h in hours" :key="h" :value="h" :selected="h == hour">{{ h }}</option>
        </select>
        <i class="ion ion-ios-arrow-down icon-right input-icon"></i>
      </label>

      <label>
        <select @change="changeHandler" class="form-control minute" v-model="minute" :disabled="disabled">
          <option v-for="m in minutes" :key="m" :value="m" :selected="m === minute">{{ m }}</option>
        </select>
        <i class="ion ion-ios-arrow-down icon-right input-icon"></i>
      </label>

      <label>
        <select @change="changeHandler" class="form-control mrd" v-model="meridian" :disabled="disabled">
          <option v-for="mrd in meridians" :key="mrd" :value="mrd" :selected="mrd === meridian">{{ mrd }}</option>
        </select>
        <i class="ion ion-ios-arrow-down icon-right input-icon"></i>
      </label>
    </div>
  </div>
</template>

<script>
  import {RangeStr} from "../../utils/helpers";

  export default {
    name: "TimeSelector",
    props: {
      label: {type: String},
      disabled: {
        type: Boolean,
        default: false
      },
      hourss:'obj.openTime',
      opnhrs:'obj.openTime',
      closehrs:'obj.closeTime',
      value: {
        type: String,
        required: true,
        default: '12:00AM',

      }
    },
    watch: {
      '$props': {
        handler: function (val, oldVal) {
          this.parseInput();
        },
        deep: true
      }
    },
    data() {
      return {
        hours: RangeStr(1, 13, 1),
        minutes: ['00', '30'],
        meridians: ['AM', 'PM'],
        hour: '12',
        minute: '00',
        meridian: 'AM',
        timestr: '',
        default: '12:00AM',
      }
    },
    created() {
      this.parseInput()
    },
    updated() {
     // this.parseInput()
    },
    methods: {
      changeHandler() {
        const timeStr = `${this.hour}:${this.minute}${this.meridian}`
        this.$emit('input', timeStr)
      },
      parseInput() {
        const matches = this.value.match(/([\d]{1,2}):([\d]{2})\s?(AM|PM)/)
        // this.value=8;
        // this.hour=8;
        if (matches.length >= 4) {

          this.hour =   matches[1].replace(/^0+/, '');
          this.minute = matches[2] == '30' || matches[2] == '00' ?  matches[2] : '00'
          this.meridian = matches[3]
          // this.value=matches[1]
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  select {
    &.form-control {
      border: 1px solid #3578c6;
      border-radius: 0 !important;
      padding: 0 10px;
      height: 35px !important;
      background-image: none !important;
      display: inline-block;

      & + .ion.input-icon:after {
        transform: translate(-19px, 2px);
      }

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }

    option {
      text-align: left;
    }
  }

  label {
    position: relative;
    width: 60px;

    & + label .form-control {
      border-left: none !important;
    }
  }

  .input-icon {
    top: 0.55rem !important;
    right: 0.5rem;
  }

  div.label {
    font-size: .6rem;
    display: block;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
    clear: both;
  }
</style>
